'use strict';

module.exports = function () {
    $('.info-icon').on('mouseenter focusin', function () {
        $(this).find('.tooltip').removeClass('d-none');
    });

    $('.info-icon').on('mouseleave focusout', function () {
        $(this).find('.tooltip').addClass('d-none');
    });

    $(document).on('mouseenter focusin', '.color-attribute', function () {
        $(this).find('.tooltip').removeClass('d-none');
    });

    $(document).on('mouseleave focusout', '.color-attribute', function () {
        $(this).find('.tooltip').addClass('d-none');
    });
    
    $('.copy-link').on('click', function (e) {
        e.preventDefault();
        const el = document.createElement('textarea');  // Create a <textarea> element
        el.value = $(this).attr('href');                                 // Set its value to the string that you want copied
        el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
        el.style.position = 'absolute';
        el.style.left = '-9999px';                      // Move outside the screen to make it invisible
        document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
        const selected =
            document.getSelection().rangeCount > 0        // Check if there is any content selected previously
                ? document.getSelection().getRangeAt(0)     // Store selection if found
                : false;                                    // Mark as false to know no selection existed before
        el.select();                                    // Select the <textarea> content
        document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(el);                  // Remove the <textarea> element
        if (selected) {                                 // If a selection existed before copying
            document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
            document.getSelection().addRange(selected);   // Restore the original selection
        }

        if ($('.show-alerts').length === 0) {
            $(this).parent().append('<div class="show-alerts"></div>');
        }
        $(this).parent().find('.show-alerts')
            .append('<div class="show-alert">' + $(this).data("text") + '</div>');
    
          setTimeout(function () {
              $('.show-alerts').remove();
          }, 3000);        
    });
};

'use strict';

var regex = new RegExp($('#formErrors').data('whitelistedchars'));
var errorMsg = $('#formErrors').data('validationerror');
var required = $('#formErrors').data('required');
var max = $('#formErrors').data('maxerror');
var chars = $('#formErrors').data('charerror');
var min = $('#formErrors').data('minerror');
var minchars = $('#formErrors').data('minreq');
var and = $('#formErrors').data('and');
var range = $('#formErrors').data('range');
var rangechars = $('#formErrors').data('rangechars');
var email = $('#formErrors').data('emailerror');
var equalTo = $('#formErrors').data('equalto');

// global form validator settings
var settings = {
    errorClass: 'is-invalid',
    errorElementClass: 'is-invalid',
    errorElement: 'span',
    errorPlacement: function(error, element) {
		element.closest('div').find('div[class=invalid-feedback]').empty().append(error);
	},
    onkeyup: false,
    onfocusout: false,
    onclick: false
};

$.extend($.validator.messages,{
    required: required,
    email: email
});

//Add validation rule to input field, with custom required message

function addRegexRule(element, options) {
    if (!options){
        options = { regexRule: regex, errorMsg: errorMsg}
    }
    
	$(element).rules("add", {
        regex: options.regexRule,
        messages: {
            regex: options.errorMsg,
        }
    })
}

function addSameEmailRule(element, emailElementID, options) {
    $(element).rules("add", {
        equalTo: emailElementID,
        messages: {
            equalTo: options.errorMsg,
        }
    })
}

if($.validator){
    $.validator.addMethod(
        "regex",
        function(value, element, regexp) {
            var re = new RegExp(regexp);
            return re.test(value);
        },
        'Regex validation'
    );
}

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
           $(this).validate(self.settings);
            var id = $(this).attr("id");

            if(id === "dwfrm_giftwrapping") {
                activateDisable($(this))
                var fields = $(this).find("textarea")
                for (let i = 0; i < fields.length; i++) {
                    addRegexRule(fields[i], {regexRule: regex, errorMsg: errorMsg});  
                }       
            }

            if(id === "dwfrm_yourinfo"){
                activateDisable($(this))
                var fields = $(this).find("input")
                for (let i = 0; i < fields.length; i++) {
                    if($(fields[i]).attr('type') !== "email" && $(fields[i]).attr('type') !== "password" && $(fields[i]).attr('type') !== "checkbox"){
                        addRegexRule(fields[i], {regexRule: regex, errorMsg: errorMsg}); 
                    }
                }
            }

            if(id === "shipping-address" || id === "dwfrm_address"){
                activateDisable($(this))
                var fields = $(this).find("input")
                for (let i = 0; i < fields.length; i++) {
                    if($(fields[i]).attr('type') !== "checkbox"){
                        addRegexRule(fields[i], {regexRule: regex, errorMsg: errorMsg});  
                    }
                }     
            }

            if(id === "dwfrm_profile"){
                activateDisable($(this))
                var fields = $(this).find("input")
                for (let i = 0; i < fields.length; i++) {
                    if($(fields[i]).attr('type') !== "email" && $(fields[i]).attr('type') !== "password" && $(fields[i]).attr('type') !== "checkbox"){
                        addRegexRule(fields[i], {regexRule: regex, errorMsg: errorMsg}); 
                    }
                    if($(fields[i]).attr('id') == 'registration-form-email-confirm'){
                        addSameEmailRule(fields[i], '#registration-form-email', {errorMsg: equalTo})
                    }
                }
            }
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    }
};

function activateDisable(form){

    var split = $(form).attr('id').split("_")
    var id = split[split.length - 1];
    var button;
    
    if($(form).closest('.checkout-left-pane').find('button[value="submit-'+id+'"]').length) {
        button = $(form).closest('.checkout-left-pane').find('button[value="submit-'+id+'"]');
    } else if($(form).find('#addAddressModalSubmitButton').length) {
        button = $(form).find('#addAddressModalSubmitButton');
    } else if($(form).closest('#shippingAddressAddEditModal').find('button[type="submit"]').length) {
        button = $(form).closest('#shippingAddressAddEditModal').find('button[type="submit"]');
    }else if($(form).find('button[type="submit"]').length){
        button = $(form).find('button[type="submit"]');
    }

    $(button).on('click', function (e) {
        if(!$(form).valid() && $(form).attr('id') == 'dwfrm_profile'){
            e.preventDefault();
        }
    });

    // $('form :input').on('change', function () {
    //     $.extend($.validator.messages,{
    //         required: required,
    //         maxlength: max + ' '+$(this).attr('maxlength')+ ' ' + chars,
    //         minlength: min + ' '+$(this).attr('minlength')+ ' ' + minchars,
    //         rangelength: range + ' '+$(this).attr('minlength')+ ' '+and+' '+$(this).attr('maxlength')+ ' ' +rangechars
    //     });
    //     if ($(this).valid()) {
    //         $(button).removeClass('button_disabled').prop('disabled', false); // enables button

    //     } else {
    //         $(button).addClass('button_disabled').prop('disabled', true);   // disables button    
    //     }
    // })

    $("form :input").on('keyup', function (e) {
        // if(e.keyCode !== 9){
        //     if($(this).attr('type') !== 'email'){
        //         if($(this).valid()){
        //             $(button).removeClass('button_disabled').prop('disabled', false)
        //         }
        //         else{
        //             $(button).addClass('button_disabled').prop('disabled', true)
        //         }
        //     }
        // }

        $.extend($.validator.messages,{
            required: required,
            maxlength: max + ' '+$(this).attr('maxlength')+ ' ' + chars,
            minlength: min + ' '+$(this).attr('minlength')+ ' ' + minchars,
            rangelength: range + ' '+$(this).attr('minlength')+ ' '+and+' '+$(this).attr('maxlength')+ ' ' +rangechars
        });

        if(id === "giftwrapping"){
            var messages = $(form).find('textarea');
            for (let i = 0; i < messages.length; i++) {
                if(!$(messages[i]).valid()){
                    $(button).addClass('button_disabled').prop('disabled', true);
                    $(messages[i]).addClass('is-invalid');
                    $(messages[i]).parent().find('span[class="error"]').addClass('invalid-feedback')
                    break;
                }
                else if($(messages[i]).valid() && $(messages[i]).hasClass('is-invalid')){
                    $(messages[i]).removeClass('is-invalid');
                }                
            }

            if($(form).valid()){
                $(button).removeClass('button_disabled').prop('disabled', false)
            }
        }

    });

    $("form #registration-no1-terms").on('click', function(e){
        if($(this).attr('type') == 'checkbox'){
            if($(this).prop('checked') == true){
           
            }
        }
    });

    $("form :input").on('focusout', function(e){
        
        var value = $(this).val().trim()
        if(value){
            $(this).valid()
        }
        
        $.extend($.validator.messages,{
            required: required,
            maxlength: max + ' '+$(this).attr('maxlength')+ ' ' + chars,
            minlength: min + ' '+$(this).attr('minlength')+ ' ' + minchars,
            rangelength: range + ' '+$(this).attr('minlength')+ ' '+and+' '+$(this).attr('maxlength')+ ' ' +rangechars
        });
    })

    
}

module.exports = validator;
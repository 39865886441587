'use strict';

var base = require('../product/base');
var focusHelper = require('../components/focus');
var coupon = require('../components/coupon');
var memberPromotionHelper = require('../components/memberPromotion');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';
            
            $('.cart-error').empty().append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
        $('.checkout-btn').data('disabled', true)
    } else {
        $('.checkout-btn').data('disabled', false)
    	$('.cart-error').empty();
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.subtotal-of-items').empty().append(data.resources.subtotalOfItems);
    $('.products-text').empty().append(data.resources.basketTotalItems);
    $('.shipping-total-cost').empty().append(data.totals.totalShippingCost);
    $('.member-total').empty().append(data.totals.memberPriceTotal);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total-sum').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    
    $('#loyaltyPointsCart').empty().append(data.loyaltyPoints.points);
    
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.product-info.uuid-' + item.UUID).find('.line-item-promotion').empty().append(item.renderedPromotions);
        } else {
        	$('.product-info.uuid-' + item.UUID).find('.line-item-promotion').empty()
        }
        
        if(item.renderedPrice){
        	$('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        	$('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        }
        
        if(item.priceTotal && item.priceTotal.renderedPrice){
        	$('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        }

        if(item.price && item.price.member){
        	$('.item-member-' + item.UUID).find('.member-total').empty().append(item.price.member.formatted);
        }
        
        if (item.quantity) {
            $('.item-quantity-' + item.UUID).empty().append(item.quantity);
        }
       
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
    
    setTimeout(function () {
        $('.alert-dismissible').remove();
    }, 3000);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid, productBrand, quantity) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);
    $deleteConfirmBtn.data('wishlist', false);
    $deleteConfirmBtn.data('quantity', quantity);
    
    $productToRemoveSpan.empty().append('<div class="brand">' + productBrand + ' </div><div class="product-name">' + productName + '</div>');   //TODO: add product brand
}

function confirmConvert(actionUrl, productID, productName, uuid, productBrand, quantity, convertType, convertMessageBody, stockInStoreMessage) {
    var $deleteConfirmBtn = $('.cart-convert-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-convert');

    $deleteConfirmBtn.attr('data-pid', productID);
    $deleteConfirmBtn.attr('data-action', actionUrl);
    $deleteConfirmBtn.attr('data-uuid', uuid);
    $deleteConfirmBtn.attr('data-wishlist', false);
    $deleteConfirmBtn.attr('data-quantity', quantity);
    $deleteConfirmBtn.attr('data-converttype', convertType)
    $('.convert-message').empty().text(convertMessageBody);
    if(stockInStoreMessage){
        $('.stockinstoremessage').show();
        $('.stockinstoremessage').text(stockInStoreMessage);
    }else{
        $('.stockinstoremessage').text('');
        $('.stockinstoremessage').hide();
    }
    
    $productToRemoveSpan.empty().append('<div class="brand">' + productBrand + ' </div><div class="product-name">' + productName + '</div>');   //TODO: add product brand
    $('#convertProductModal').modal('show');
}

function showAddedToWishlistMessage(data){
	if(data.resources && data.resources.addedToWishlist){
	    if ($('.notification-pop-up-messages').length === 0) {
	    	$('body').append('<div class="notification-pop-up-messages "></div>');
	    }
	    
	    $('.notification-pop-up-messages')
	        .append('<div class="notification-pop-up-alert text-center alert-success">' + data.resources.addedToWishlist + '</div>');

	    setTimeout(function () {
	        $('.notification-pop-up-messages').remove();
	    }, 5000);
	}
}

function updateLoyaltyPoints(data) {
    if($('#loyaltyPointsCart').length){
        $('#loyaltyPointsCart').empty().append(data.loyaltyPoints.points);
    }
}

function limitInput(char, input, maxlength, regex) {
    var $input = $(input);
    if(char){
        // if ($input.val().indexOf(char) == $input.val().length-1) {
        //     $input.val($input.val().slice(0, $input.val().length - 1));
        // }else if($input.val().indexOf(char) == 0){
        //     $input.val($input.val().slice(1, $input.val().length));
        // }else{
        $input.val($input.val().slice(0, $input.val().indexOf(char)) + $input.val().slice($input.val().indexOf(char)+1, $input.val().length));
        // }
    }
   else if(!char){
        var temp = $input.val().split('');
        var result="";
        for (let i = 0; i < maxlength; i++) {
            if(regex.test(temp[i])){
                result = result + temp[i];
            }
        }
        $input.val(result);
    }
    if ($input.val().length > maxlength) {
        $input.val($input.val().slice(0, maxlength));
    }
};

module.exports = function () {
    coupon.couponCode();

    $('body').on('propertychange input', '.form-control.quantity-select', function (e) {
        var regex = new RegExp('^[0-9]+$');
        if($(this).val().indexOf(0) == 0){
            $(this).val($(this).val().replace('0', ''));
            if($(this).val().length<1){
                $(this).val(1);
            }
        }
        var char = e.originalEvent.data;
        var value = $(this).val();
        var maxlength = 3;
        if($(this).attr('id') == 'quantity-gift-registry-selected'){
            maxlength = 8;
        }

        if(!regex.test(value) || $(this).val().length > maxlength){
            limitInput(char,this,maxlength,regex)
        }
    });

    $('body').on('focusout', '.form-control.quantity-select', function (e) {
        var val = $(this).val();
        if(!val){
            $(this).val('1');
        }
    })

	$('body').on('click', '.add-to-wish-list-cart', function (e) {
        e.preventDefault();
        var url = $(this).data('wishlist-remove-action');
        var pid = $(this).data('pid');
        var element = $(this);
        if (!url || !pid) {
            return;
        }
        
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                optionId: null,
                optionVal: null
            },
            success: function (data) {
            	var removeButton = $(element).closest('.line-item-links').find('.remove-product');
            	var actionUrl = $(removeButton).data('action');
                var productID = $(removeButton).data('pid');
                var productName = $(removeButton).data('name');
                var uuid = $(removeButton).data('uuid');
                var quantity = $(removeButton).data('quantity');
               
                var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');

                $deleteConfirmBtn.data('pid', productID);
                $deleteConfirmBtn.data('action', actionUrl);
                $deleteConfirmBtn.data('uuid', uuid);
                $deleteConfirmBtn.data('wishlist', true);
                $deleteConfirmBtn.data('quantity', quantity);
                
                $('.cart-delete-confirmation-btn').click();
            },
            error: function (err) {
            }
        });
    });

    $('body').on('click', '.checkout-btn', function (e) {
        e.preventDefault();
        var url = $(this).data('validate');
        var href = $(this).attr('href');
        if($(this).data('disabled')){
            $('#notInStockModal').modal('show');
        }else{
            $.ajax({
                url: url,
                type: 'get',
                success: function (data) {
                    if(!data.error){
                        window.location.href = href;
                    }
                    else{
                        $('#notInStockModal').find('.modal-body').empty().append(data.template)
                        $('#notInStockModal').modal('show')
                    }
                },
                error: function (err) {
                    console.log('error', err)
                }
            })            
        }
    })

    $('body').on('click', '.cart-convert-confirmation-btn', function (e) {
        var url = $(this).attr('data-action');
        $.ajax({
            url: url,
            type: 'post',
            success: function (data) {
                if(!data.error){
                    $('.shipment-container').replaceWith(data.shipmentTemplate);
                    $('.checkout-order-overview').replaceWith(data.orderOverviewTemplate);
                    updateLoyaltyPoints(data.basket);
                    validateBasket(data.basket);
                }
                else{
                    createErrorNotification(data.message);
                    // $.spinner().stop();
                }
            },
            error: function (err) {
                console.log('error', err)
            }
        })
    })

    $('#convertProductModal ').on('hide.bs.modal', function () {
        var confirmConvertButton = $('.cart-convert-confirmation-btn')
        var convertType = confirmConvertButton.attr('data-converttype');
        var pid = confirmConvertButton.attr('data-pid')
        var quantSelect = $('.quantity-select[data-pid="'+pid+'"]')
        if(convertType == 'order'){
            quantSelect.val(quantSelect.val()-1)
        }
    })
	
    $('body').on('click', '#notInStockModal .btn-primary', function (e) {
        $('#notInStockModal').modal('hide');
    })

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var productBrand = $(this).data('brand');
        var uuid = $(this).data('uuid');
        var quantity = $(this).data('quantity');
        confirmDelete(actionUrl, productID, productName, uuid, productBrand, quantity);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var quantity = $(this).data('quantity');
        var addedToWishListTrigger = $(this).data('wishlist');
        var urlParams = {
            pid: productID,
            uuid: uuid,
            quantity: quantity
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.ajax({
            url: url,
            type: 'get',
            success: function (data) {
                if (data.memberPromotionClass) {
                    memberPromotionHelper.updateMemberPromotionFlag(data.memberPromotionClass);
                }
                if(data.emptyCart){
                    // window.location.href = data.continueUrl;
                    if (data.removedProduct) {
                        var dataLayer = data.removedProduct;
                        dataLayer.forEach(function (item) {
                            window.dataLayer.push(item);
                        });
                    }
                    $('.cart-top-content .cart-header').remove();
                    $('.shipment-container').remove();
                    $('.cart-top-content .cart-error').remove();
                    $('.cart-error').empty();
                    $('.order-summary-wrapper').remove();
                    $('.cart-top-content .page-title').empty().append(data.renderedEmptyCartTemplate);
                    $('.minicart .minicart-total').addClass('empty');
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                }else{
                    if (data.removedProduct) {
                        var dataLayer = data.removedProduct;
                        dataLayer.forEach(function (item) {
                            window.dataLayer.push(item);
                        });
                    }
                    $('.shipment-container').replaceWith(data.shipmentTemplate);
                    $('.checkout-order-overview').replaceWith(data.orderOverviewTemplate);
                    updateLoyaltyPoints(data.basket);
                    validateBasket(data.basket);
                    
                }
                // if (data.basket.items.length === 0) {

                //     if (data.removedProduct) {
                //         var dataLayer = data.removedProduct;
                //         dataLayer.forEach(function (item) {
                //             window.dataLayer.push(item);
                //         });
                //     }
                //     $('.cart').empty();
                //     $('.cart-header').empty();
                //     $('.cart-error').empty();
                //     $('.page-title').empty().append(data.renderedEmptyCartTemplate);
                //     $('.minicart .minicart-total').addClass('empty');
                //     $('.minicart .popover').empty();
                //     $('.minicart .popover').removeClass('show');
                //     $('body').removeClass('modal-open');
                //     $('html').removeClass('veiled');
                // } else {
                //     if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                //         for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                //             $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                //         }
                //     }

                //     if (data.removedProduct) {
                //         var dataLayer = data.removedProduct;
                //         dataLayer.forEach(function (item) {
                //             window.dataLayer.push(item);
                //         });
                //     }    

                //     $('.uuid-' + uuid).remove();

                //     if(!$('#shipment-'+data.shipmentUUID).find('.card.product-info').length){
                //         $('#shipment-'+data.shipmentUUID).remove();
                //     }
                    
                //     if (!data.basket.hasBonusProduct) {
                //         $('.bonus-product').remove();
                //     }
                //     $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                //     updateCartTotals(data.basket);
                //     updateApproachingDiscounts(data.basket.approachingDiscounts);
                //     $('body').trigger('setShippingMethodSelection', data.basket);

                // }
                
                // if(addedToWishListTrigger){
                // 	showAddedToWishlistMessage(data);
                // }
                
                // Updates shipment info banner.
                if (data.basket.numOfShipments > 1) {
                    $('.container .cart-info-banner').show();
                    $('.container .cart-info-banner-header').html(data.basket.resources.shipmentInfoBanner);
                } else {
                    $('.container .cart-info-banner').hide();
                }

                $('body').trigger('cart:update');
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                }
            }
        });
    });

    $('body').on('change', '.quantity-form > .quantity-spinner > .quantity-select', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
      

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                if(data.quantityerror){
                    $(this).val(data.quantity);
                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.add-to-cart-messages').append(
                            '<div class="alert alert-danger add-to-basket-alert text-center" role="alert">'
                            + data.message
                            + '</div>'
                    );
        
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                    }, 5000);
                }else if(data.promptConvert && data.convertType == 'order'){
                    $('#convertProductLineItemModal').text(data.convertMessage);
                    confirmConvert(data.convertUrl, productID, data.productName, uuid, data.productBrand, data.quantity, data.convertType, data.convertMessageBody);
                }else{
                    $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    $('.remove-product').data('quantity', quantity);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    if (data.memberPromotionClass) {
                        memberPromotionHelper.updateMemberPromotionFlag(data.memberPromotionClass);
                    }
                    //updateAvailability(data, uuid);
                    validateBasket(data);
                    $(this).data('pre-select-qty', quantity);

                    $('body').trigger('cart:update');

                    var selector = $('.item-per-' + uuid);
                    if(quantity > 1){
                        selector.show();
                        selector.find('.line-item-per-price-amount').show()
                        if(selector.find('.item-member-'+uuid).length){
                            selector.find('.item-member-'+uuid).show();
                            selector.find('.unit-label').show();
                        }
                        if(!selector.find('.line-item-total-price .line-item-total-price-amount').closest('.price').find('.strike-through').hasClass('non-adjusted-price') && selector.find('.strike-through').data('list')){
                            selector.find('.strike-through').removeClass('non-adjusted-price')
                        }else{
                            if(!selector.find('.strike-through').hasClass('non-adjusted-price')){
                                selector.find('.strike-through').addClass('non-adjusted-price')
                            }
                        }
                    }else{
                        selector.hide();
                        selector.find('.line-item-per-price-amount').hide()
                        if(selector.find('.item-member-'+uuid).length){
                            selector.find('.item-member-'+uuid).hide()
                            selector.find('.unit-label').hide();
                        }
                        if(!selector.find('.line-item-per-price .strike-through').hasClass('non-adjusted-price')){
                            selector.find('.line-item-per-price .strike-through').addClass('non-adjusted-price')
                        }
                    }
                    
                    //If product was unavailable before adjusting quantity, remove unavailable state from product
                    if($(this).closest('.unavailable').length > 0 && $(this).closest('.unavailable').prev('.product-info').length > 0){
                        $(this).closest('.unavailable').prev('.product-info').find('.item-product-details').find('.line-item-unavailable').empty();
                        $(this).closest('.unavailable').prev('.product-info').removeClass('unavailable');
                        $(this).closest('.unavailable').removeClass('unavailable');
                    }else{
                        $(this).closest('.item-product-details').find('.line-item-unavailable').empty();
                        $(this).closest('.product-info').removeClass('unavailable');
                    }
                    
                    if($('.cart-page').length){
                        //Reload page if bonus products have been added or removed from basket
                        var productsInCart = $('.product-info');
                        if(productsInCart.length != data.items.length){
                            location.reload();
                        }
                    }
                    
                    var dataLayer = data.updatedProduct;

                    if(dataLayer){
                        dataLayer.forEach(function (item) {
                            window.dataLayer.push(item);
                        });
                    }

                    if(data.promptConvert && data.convertType == 'stock'){
                        $('#convertProductLineItemModal').text(data.convertMessage);
                        confirmConvert(data.convertUrl, productID, data.productName, uuid, data.productBrand, data.quantity, data.convertType, data.convertMessageBody, data.stockInStoreMessage);
                    }
                }

            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };

        $('.totals').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.cart-page .promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if($('.update-cart-product-global').length && 
        (!$('.update-cart-product-global').hasClass('barcode-add-product') 
        && !$('.update-cart-product-global').hasClass('btn-update-gift-registry-product'))){
            $('.update-cart-product-global', dialog).attr('disabled',
                !$('.global-availability', dialog).data('ready-to-order')
                || !$('.global-availability', dialog).data('available')
            );
        }
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update');

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });
    $('<div class="spacer" style="display: block; width: '+$('.checkout-continue').width()+'px; height: '+$('.checkout-continue').outerHeight(true)+'px;"></div>').insertAfter($('.checkout-continue'));
    $(window).on('scroll', function() {
        if(window.innerWidth < 768) {
        	if($('.cart-page .totals').length) {
	            var bottomOfParent = $('.cart-page .totals').offset().top + $('.cart-page .totals').outerHeight(true) - $(window).height();
	            if($(window).scrollTop() > bottomOfParent) {
	                $('.spacer').remove();
	                $('.checkout-continue').addClass('stuck');
	            } else {
	                if($('.spacer').length === 0) {
	                    $('<div class="spacer" style="display: block; width: '+$('.checkout-continue').width()+'px; height: '+$('.checkout-continue').outerHeight(true)+'px;"></div>').insertAfter($('.checkout-continue'));
	                    $('.checkout-continue').removeClass('stuck');
	                }
	            }
        	}
        } else {
            $('.spacer').remove();
            $('.checkout-continue').removeClass('stuck');
        }
        if(window.innerWidth > 1024) {
            $('.order-summary').stick_in_parent();
        }
    });

    base.selectAttribute();
    base.colorAttribute();
    base.generalAttribute();
    base.sizeGuide();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
    base.quantityChangeProductCard();
    base.storeInventory();
};

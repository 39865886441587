'use strict';

module.exports = function () {
    //ILB-264
    $('.powerstep').on('powerstep:show', function (event, data) {
        if(!data.error) {
            if(data.addedQuantity > 1) {
                $('.powerstep .modal-title').html(data.addedQuantity + " " + $('.powerstep #multi-pieces-modal-title').text());
            } else {
                $('.powerstep .modal-title').html($('.powerstep #one-piece-modal-title').text());
            }
            var tecSysMessage;
            if (data.tecSysMessage){
                tecSysMessage = data.tecSysMessage
            }
            var url = $('.powerstep').data('action-url');
            var data = {"productId": data.addedProductId};
            $.ajax({
                url: url,
                method: 'POST',
                data: data,
                success: function (response) {
                	 $('.powerstep .modal-body').find('.modal-recommendations').siblings().remove();
                     $('.powerstep .modal-body').prepend(response);
                     $('#modalPowerStep').modal('show');
                     if(tecSysMessage){
                        if($('.tecsysmessage').length){
                            $('.tecsysmessage').empty().html(tecSysMessage);
                            $('.tecsysmessage').parent().show();
                        }
                    }
                }
            });
        }
    });
};
